export const hcpSYP: any = {
    hcpHomeMainMenu: "Main Navigational Menu HCP GCSO",
    hcpHomeFooterMenu: "Footer Navigation Menu HCP GCSO",
    hcpHomeCarousel: "",
    hcpBanner: "Banner - Supporting Your Patient",
    hcpHTMLBanner: "Banner - HTML - SYP - HCP - Canada",
    hcpFooter: "Retina Global Footer HCP - Canada",
    hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
    hcpHCPSwitcher: "HCP Switcher Modal Pop Up",
    hcpTopNav: "Top Navigational Menu HCP - Canada",
    hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
    hcpSiteLogo: "Site logos HCP",
    hcpAmericalData: "American Academy of Ophthalmology HTML - Canada",
    hcpAdvancingScience: "Advancing Science HTML - Canada",
    hcpOverlap: "Overlapping presentations of IRD HTML - Canada",
    hcpFootRef: "HCP-Supporting Your Patient-References",
    hcpHomeVideoCarousel: "DNA Video and Carousel",
    hcpAnchorelinkPara: "Anchor Link - Resource Center - Retina Canada",
    hcpRealWorldPara: "Real World Case Studies - Supporting Your Patient",
    hcpClinicalTrialPara: "HCP-Supporting Your Patient-Seeking a Clinical Trial",
    hcpVideoHeadingPara: "Video Heading Supporting your patient",
    hcpPatientKnowPara: "Logo garden section - Canada - HCP - Supporting your patients",
    hcpRCSModal1: "Revealing Modal",
    hcpRCSModal2: "Overlapping Modal",
    hcpRCSModal3: "Denovo Modal",
    hcpRCSModal4: "Retesting Modal",
    hcpRCSModal5: "Benefit Modal",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/hcp/supporting-your-patients",
        "fr": "/fr/hcp/supporting-your-patients"
      }
    },
    backToTopGTM: "Back to top - supporting your patients",
    footerClassName: "supporting-your-patients-footer"
  }
