import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const SupportingYourParentsWrapper = styled.div`
	&.supportparentswrapper {
		.supporting-patients-video-section {
			.thumbnail-video-section {
				@media ${device.tablet} {
					padding: 0;
				}

				h2 {
					&.o-internal--h2 {
						font-size: 4.2rem;
						font-weight: 700;
						line-height: 5rem;
						color: #000000;
						text-align: center;
						padding-bottom: 40px;

						@media ${device.laptopMedium} {
							font-size: 2.5rem;
							line-height: 3rem;
							padding-bottom: 24px;
						}
					}
				}

				h3 {
					&.o-internal--h2 {
						display: none;
					}

					~p {
						display: none;
					}
				}

				.video-container {
					@media ${device.laptopPros} {
						padding: 0;
					}

					@media ${device.tablet} {
						padding: 0;
					}

					.o-inner {
						padding: 0;

						.video-thumbnail-section-main {
							@media ${device.laptopMedium} {
								align-items: unset;
							}

							.video-thumbnail-left {
								@media ${device.laptopMedium} {
									order: 1;
								}

								.mx-auto {
									width: 100% !important;
									max-width: 100% !important;
								}
							}

							.video-thumbnail-right {
								@media ${device.laptopMedium} {
									order: 4;
									margin-top: 20px;
								}

								.o-common-title {
									p {
										@media ${device.laptopMedium} {
											line-height: 2.6rem !important;
										}

										strong {
											font-weight: 700;
										}
									}
								}

								.o-paragraph {
									p {
										color: #000000;
										width: 80%;
										padding-top: 27px;
										font-size: 1.8rem;
    								line-height: 2.6rem;
										font-weight: 500;
                    font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
										padding: 0;

										@media ${device.laptopMedium} {
											width: 100%;
											font-size: 1.8rem !important;
    									line-height: 2.6rem !important;
										}
									}
								}
							}

							.community-video-btn {
								transition: all 0.3s ease;

								@media ${device.laptopMedium} {
									order: 2;
								}

								.dtp-arrow-down {
									&:before {
										@media ${device.laptopMedium} {
											top: 4px;
											position: relative;
										}
									}

									&.rotate {
										&:before {
											position: relative;
											top: -5px;

											@media ${device.laptopMedium} {
												top: 2px;
											}
										}
									}
								}
							}

							.video-transcript-copy {
								background: #fff;
								box-shadow: none;
								height: 185px;

								@media ${device.laptopMedium} {
									order: 3;
									margin-top: 0;
									padding: 20px 20px 20px 0;
								}

								p {
									margin: 0;
								}
							}

							&:last-child {
								padding: 0;
							}
						}
					}
				}
			}
		}
		.clinical-trials-banner{
      height: 100%;
      .internal-banner{
        position: relative;

        @media ${device.desktopmenu}{
          height: 100%;
        }
        .internal-banner__contant{
          .o-container{
            padding: 0 3rem;
            @media ${device.tablet}{
              padding: 0 2rem;
            }
            @media ${device.ipadLandscapemin} and ${device.midDesktopMax}{
              padding: 0 5rem;
            }
            h1{
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin: 15px 0 0;
                width: 100% !important;
              }
            }
          }
        }
        .gatsby-image-wrapper-constrained{
          @media ${device.laptopL}{
            width: 100%;
          }
          @media ${device.laptopMedium}{
            width: 100%;
            height: 470px;
            object-position: 90% 0%;
          }
          @media ${device.ipadLandscapemin}{
            height: 100%;
          }

        }
        .internal-banner__image{
          height: 310px;
          @media ${device.mobileStart}{
            height: 470px;
          }
          @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
            height: 290px;
          }
          @media ${device.smalldeskstrt} {
            height: 100%;
          }
          .desktop-image{
            height: 100%;
              .media--image{
                height: 100%;
              }
            img{
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: center;

              @media ${device.laptopPros}{
                display: none;
              }
              @media ${device.tablet} and  ${device.laptopMedium} {
                object-position: 90% 0%;
              }
              @media ${device.ipadLandscapemin} {
                width: 100%;
              }
              @media ${device.desktopsignup}{
                margin: -2px 0;
              }
            }
          }
          .mobile-image{
            .media--image{
              height: 100%;
            }
            img{
              display: none;
              height: 310px;
              object-position: center !important;
                @media ${device.mobileStart}{
                  height: 470px;
                }
                @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                  height: 290px;
                }
                @media ${device.smalldeskstrt} {
                  height: 100%;
                }
              @media ${device.laptopPros}{
                display: block;
                object-fit: cover;
                width: 100%;
              }
            }
          }
        }
        .o-header--h1{
          @media ${device.desktopsignup}{
            font-family: "Gotham", Arial, Helvetica, sans-serif;
            font-size: 53px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21 !important;
            letter-spacing: normal;
            text-align: left;
            padding-left: 25px;
          }
        }
      }
    }

    .resource-anchor-links-block {
			margin-top: 50px;
			padding-top: 24px;

			@media ${device.laptopPros} {
				margin-top: -20px;
			}
      @media ${device.tablet} {
				margin-top: 10px;
        padding-top: 5px;
			}

			p{
				font-size: 2.7rem;
				line-height: 3.8rem;
				width: 76%;
				font-weight: 700;
				text-align: center;
			}
			.resource-head {
				@media ${device.laptopMedium} {
					width: 100%;
					font-size: 2rem;
					line-height: 2.6rem;
					margin-bottom: 30px;
				}
			}
			.resource-links {
				width: auto;
				margin: auto;
    		display: flex;
			  justify-content: space-around;
				@media ${device.laptopMedium} {
					display: none;

				}
        @media ${device.ipadLandscapemin} {
          margin-top: 60px;
        }

				a {
					background: #f7f7f7;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 45px;
					padding-right: 30px;
					text-decoration: none;
					font-weight: 500;
					text-align: center;
					justify-content: space-between;
					color: #8000BE;
					font-size: 2rem;
					max-width: 480px;
					height: fit-content;
					line-height: 3.8rem;

					&:nth-child(1) {
						margin-right: 3rem;
					}
					&:nth-child(2) {
						margin-right: 3rem;
					}


					@media ${device.ipadLandscapemin} {
						box-shadow: rgb(0 0 0 / 30%) 0px 2px 5px 0px;
					}

					&:after {
						content: "";
						width: 30px;
						height: 26px;
						display: inline-block;
						margin-left: 10px;
						position: relative;
						top: 2px;
    				vertical-align: middle;
					  background-size: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNy4zMzMiIHZlcnNpb249IjEuMCIgdmlld0JveD0iMCAwIDEyIDEzIj48ZyBmaWxsPSIjODAwMEJFIj48cGF0aCBkPSJNMTAgMTEyYzAtNSAxMS0xOSAyNC0zMWwyNC0yMiAyOCAyNWMxNiAxMyAyNiAyOCAyMiAzMi01IDQtMTctMS0yOC0xMS0xOS0xNy0yMS0xNy0zOC0yLTIwIDE5LTMyIDIyLTMyIDl6IiB0cmFuc2Zvcm09Im1hdHJpeCguMSAwIDAgLS4xIDAgMTMpIi8+PHBhdGggZD0iTTEwIDYzYzAtNSAxMS0yMCAyNS0zM0w2MSA2bDI2IDI3YzMyIDMyIDI2IDQ2LTggMTgtMjQtMTktMjQtMTktNDYgMS0xMyAxMi0yMyAxNy0yMyAxMXoiIHRyYW5zZm9ybT0ibWF0cml4KC4xIDAgMCAtLjEgMCAxMykiLz48L2c+PC9zdmc+);
            background-repeat: no-repeat;
            }
					}

					@media ${device.laptopair2max} {
						padding: 10px 10px;
						font-size: 1.4rem;
						min-width: 290px;
    				text-align: center;
					}
					@media ${device.laptopMedium} {
						padding: 10px 10px;
					}
					@media ${device.laptopPros} {
						padding: 10px 10px;
						font-size: 1rem;
					}
				}
			}

		}
		.logo-section {
			text-align: center;
			padding-top: 80px;

			@media ${device.laptopMedium} {
				padding-top: 30px;
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				max-width: 950px;
				margin: 0 auto;
				text-align: center;
				color: #000000;

				@media ${device.laptopMedium} {
					font-size: 2.5rem;
    			line-height: 3rem;
					width: 100%;
				}
			}
      .o-header--h3 {
        font-size: 2.7rem;
      }

			.o-block--center__small {
				max-width: 780px;
				margin: 0 auto;
				font-size: 2.2rem !important;
				font-weight: 500 !important;
				line-height: 3.2rem !important;
				color: #000000 !important;
				text-align: center;
				padding: 40px 0 24px 0;
				font-family: "Gotham-Book", Arial, Helvetica, sans-serif !important;

				@media ${device.laptopMedium} {
					font-size: 2rem !important;
					line-height: 2.6rem !important;
					width: 100%;
					padding: 24px 30px;
				}
			}

			.o-header--h4 {
				color: #8000BE;
				text-align: center;
				font-weight: 700;
				font-size: 2.7rem;
				line-height: 3.8rem;

				@media ${device.laptopMedium} {
					font-size: 2rem;
    			line-height: 2.4rem;
					width: 90%;
    			margin: 0 auto;
				}
			}

			.o-header {
				&.o-header--h3 {
          color: #8000BE;
					&.btm-logo-title {
						&.second-heading {
							padding: 0;
							margin: 25px 0 0;

              @media ${device.mobileS} and ${device.desktopmenu} {
                margin: 45px 0 0;
              }
              line-height: 3.8rem;
              @media ${device.laptopMedium}{
                font-size: 2rem;
                line-height: 2.4rem;
              }
						}
					}
				}
			}

			.logo-block {
				&.o-image-wrapper-main,
				&.btm-logo-block {
					margin: 0 0 30px;
					width: 100%;
					display: block;
					padding-top: 24px;
					margin: 0;


          @media ${device.tablet} and ${device.laptopMedium} {
            margin-bottom: 30px;
          }
          @media ${device.ipadLandscapemin} and ${device.laptopair2max}{
            padding-top: 0px;
            margin-bottom: 0px;
          }

            @media ${device.desktopsignup} and ${device.midDesktopMax} {
              padding-top: 0px;
            }


          @media ${device.desktopStart} {
            margin-bottom: 42px;
            padding-top: 0;
          }
          @media ${device.largeDesktop} {
            padding-top: 5px;
          }

					ul {
						display: inline-flex;
						margin: 0;
						padding: 0;
						justify-content: unset;
            max-width: 100%;
            padding-top: 24px;

            @media ${device.tablet}{
              margin-bottom: 18px !important;

              @media ${device.desktopsignup} and  ${device.midDesktopMax} {
                padding-top: 0px;
                margin-bottom: 0;
              }
            }

						li {
							display: block !important;
							height: 24rem !important;
							line-height: normal;
							width: 100%;
              padding-left: 30px;
							text-align: center;
							border-right: 1px solid #707070;
							border-bottom: 1px solid #707070;
							align-items: unset;
							justify-content: unset;
							float: none;
              margin: auto;
              @media ${device.laptopMedium}{
                height: 100% !important;
              }
              @media ${device.desktopsignup} and ${device.midDesktopMax}{
                height: 20rem !important;
              }
              @media ${device.desktopStart} {
                height: 22rem !important;
              }

              &:nth-child(1){
                padding: 0 30px 0 0 !important;
                @media ${device.ipadLandscapemin}{
                  padding: 0px 30px !important;
                }
                img{
                  width: 70% !important;
                  @media ${device.laptopMedium}{
                    width: 100% !important;
                  }
                }
                .logo-garden-desc{
                  @media ${device.ipadLandscapemin}{
                    padding-top: 50px !important;
                  }
                  @media ${device.desktopsignup}{
                    padding-top: 30px !important;
                  }
                  @media ${device.desktopStart}{
                    width: 90% !important;
                  }

                }
              }


							&:nth-child(2) {
								border-right: 0!important;
                height: 100%;
                padding: 20px 0 20px 30px !important;
                @media ${device.mobilemin} {
									padding-right: 0 !important;
								}

                @media ${device.ipadLandscapemin}{
                  padding: 20px 10px 20px 30px !important;
                }
                @media ${device.desktopsignup}{
                  padding: 0 0 0 30px !important;
                  width:60%;
                }
                img{
                  width: 70%;
                  max-width: 80%;

                }
                .logo-garden-desc{
                  @media ${device.desktopsignup}{
                    padding-top: 45px !important;
                  }
                  @media ${device.desktopStart}{
                    width: 80% !important;
                  }
                }
							}
              &:last-child{
                border-right: 0;
              }

							@media ${device.minimumDeskEnd} {
								display: flex;
								justify-content: center;
								width: 50%;
								padding: 20px 10px;
								height: auto;
								line-height: 0;
							}

              a.extlink{
                border-right: none;
                img{
                  width: 50% !important;
                  padding-left: 30px;
                  @media ${device.mobileS} and ${device.mobileMedium} {
                    width: 80% !important;
                  }
                  @media ${device.ipadLandscapemin} and ${device.laptopair2max}{
                    height: 117px;
                    width:41%;
                  }
                  @media ${device.desktopsignup} and ${device.midDesktopMax} {
                   height: 180px;
                   width: 55%;
                  }
                  @media ${device.desktopStart} {
                    width: 100% !important;
                  }
                }
              }
							a {
								cursor: pointer;

								img {
									vertical-align: middle;
									text-align: center;
									margin: 0 auto;
                  width: auto;
                  vertical-align: middle;
                  text-align: center;
                  max-width: 80%;
                  height: 100%;
                  line-height: normal;

                  @media ${device.ipadLandscapemin} and  ${device.laptopair2max}{
                    margin: 20px auto;
                  }
								}
							}
              .logo-garden-desc{
                margin: 0 auto;
                padding-top: 86px !important;
                line-height: normal;
                @media ${device.desktopmenu}{
                  padding-top: 40px !important;
                }
                @media ${device.desktopsignup}{
                  width: 100% !important;
                }

              }
						}

						&:last-child {
							li {
								border-bottom: 0;
                height: 100%;

                img{
                  max-width: 90%;
                }
							}

						}
					}
				}
			}
		}

		.supporting-your-patients-case-studies {
			margin: 50px 0 !important;
			position: relative;

      @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
        margin: 20px 0 !important;
      }
			@media ${device.laptopMedium} {
				margin: 0 0 48px;
			}

			&:before {
				content: "";
				background: #00FFD9;
				display: block;
				width: 100%;
				height: 70%;
				position: absolute;
				top: 15%;

				@media ${device.laptopMedium} {
					top: 5%;
					height: 92%;
				}
			}

			.o-container {
				.c-family--story__inner {
					background-color: #f7f7f7;
					box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
					position: relative;
					padding: 10rem 9rem;
					color: #000000;
					text-align: center;

					@media ${device.laptopMedium} {
						padding: 2rem 3rem;
						font-size: 2rem;
    				line-height: 2.6rem;
					}

					&:before {
						height: 16px;
						content: "";
						background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}

					.o-header--h2 {
						font-size: 4.2rem;
						font-weight: 600;
						line-height: 5rem;
						color: #000000;
						margin: 0 0 40px;

						@media ${device.laptopMedium} {
							text-align: center;
							margin: 30px 0 24px;
							font-size: 2.5rem;
    					line-height: 3rem;
						}
            sup{
              font-size: 60%;
            }
					}

					.o-paragraph {
						font-size: 1.8rem;
						line-height: 2.6rem;
						width: 80%;
						margin: 0 auto 44px;
						text-align: center;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							margin: 0 auto 24px;
							width: 100%;
						}
					}

					.o-columns--row {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						justify-content: space-between;
						align-items: stretch;
						margin: 30px 0 0;

						@media ${device.laptopMedium} {
							margin: 0;
							justify-content: stretch;
							display: block;
						}

						.o-columns--one {
							width: 49% !important;
							margin: auto;

							@media ${device.laptopMedium} {
								width: 100% !important;
								padding: 15px 20px !important;
								margin: 0 0 20px;
							}
						}

						.o-columns--two,
						.o-columns--one {
							padding: 30px 25px 100px;
							box-sizing: border-box;
							position: relative;
							text-align: center;
							width: 46.5%;
							background: #fff;

							@media ${device.laptopMedium} {
								padding: 25px;
								margin: 0;
								margin-bottom: 16px;
								width: 100%;
							}

							.o-header--h3 {
								color: #8000BE;
								font-size: 2.7rem;
								line-height: 3.8rem;
								font-weight: 600;

								@media ${device.laptopMedium} {
									font-size: 1.8rem !important;
									line-height: 2.6rem !important;
									width: 100%;
									text-align: center;
								}
							}

							#retesting-study {
								@media (max-width: 350px) {
									word-break: break-word;
								}
							}

							.o-paragraph {
								font-size: 1.8rem;
    						line-height: 2.6rem;
								margin: 30px auto;
    						width: 100%;
								font-weight: 500;
                font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
								color: #000000;

								@media ${device.laptopMedium} {
									margin: 16px 0 32px 0;
									font-size: 1.8rem;
									line-height: 2rem;
								}
							}

							.o-button {
								box-shadow: 0 3px 4px #d6d6d6;
								position: absolute;
								bottom: 40px;
								left: 0;
								right: 0;
								margin: 0 auto;
								display: inline-block;
								max-width: 252px;
								padding: 10px;
								background: #8000BE;
								color: #fff;
								border: 1px solid #fff;
								font-size: 2.2rem;
								font-weight: 500;
								line-height: 3.2rem;
								text-decoration: none;
								z-index: 1;
								vertical-align: top;
								overflow: hidden;
								letter-spacing: 0;
    						border-radius: 6px;
								transition: all 0.3s ease;
    						cursor: pointer;

								@media ${device.laptopMedium} {
									position: unset;
									font-size: 1.8rem;
									line-height: 2.6rem;
								}

								&:hover {
									background: #fff;
									color: #8000BE;
									border-color: #fff;
									font-weight: 700;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}

		.supporting-your-patients-clinical-trail {
			@media ${device.tablet} and ${device.laptopMedium} {
				padding: 0 5rem;
			}

			.text-bg-box__space {
				padding: 88px 90px 50px;
				margin: 100px 0 0;
				background-color: #8000BE;

				@media ${device.laptopMedium} {
					margin: 0;
					padding: 48px 30px 28px;
				}

				.o-internal--h2 {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #fff;
					text-align: center;

					@media ${device.laptopMedium} {
						line-height: 2.6rem;
    				margin-bottom: 10px;
						text-align: center;
						font-size: 2.5rem;
					}
				}

				.o-paragraph {
					width: 80%;
					margin: auto;
    			text-align: center;
					padding: 40px 0;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #fff;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.6rem;
						padding-bottom: 20px;
						padding-top: 20px;
						width: 100%;
					}

					sup {
						font-size: 60%;
						top: -0.5em;
						position: relative;
					}

					a {
						display: block;
						text-decoration: underline;
						color: #fff !important;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						&:hover {
							text-decoration: none;
						}

						span {
							color: #8000BE;
							display: none;

							.link-img {
								position: initial;
								padding: 0 5px;
								margin-top: -10px;
							}
						}
					}
				}
			}
		}

		.patients-questions-wrapper {
			h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;
				padding: 0 0 40px 0;

				@media ${device.laptopMedium} {
					top: 5%;
    			padding: 0 0 24px 0;
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}

			.o-paragraph--center {
				max-width: 780px;
				margin: 0 auto 40px;
				width: 80%;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				text-align: center;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					width: 100%;
				}
			}
		}
	}
`;
export const HcpSYPmainwrapper = styled.div`
  &.hcpsupportingpatientwrapper{
    #patient-support{
      height:0;
    }
    .hcpsupportpatience{
      &:not(.patient-genetic){
        .show{
        +.main {
            padding-top: 0px;
          }
        }
      }
      .supportparentswrapper{
        .logo-section{
          .logo-block{
            .o-image-wrapper-main{
              ul{
                @media ${device.ipadLandscapemin}{
                  width: 50% !important;
                }
              }
          }
        }
      }
    }
  }
  .supporting-your-patients-footer {
    .o-backtotop-btn {
      @media ${device.laptopMedium} {
        top: 30px;
      }
    }
  }

  .footer{
    margin:0;
    .footercontainer {
      footer {
        >.row {
          padding: 8rem 0 3rem;
        }
      }
    }
  }
`;
export const SupportingYourParentsModalWrapper = styled.div`
	width: 100%;

	.modal-body {
		padding: 0;
    text-align: left;
		position: relative;
		flex: 1 1 auto;

    .o-header--h3{
      font-family: "Gotham", Arial, Helvetica, sans-serif;
      font-weight: 600;
    }
    .retina-modal-body-container{
       p{
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        }
    }
		.button-close {
			color: #fff;

			@media ${device.ipadLandscape} {
				background-size: 25px;
				background-repeat: no-repeat;
				right: -10px !important;
				text-align: right;
				float: right;
			}
		}

		.modal-header {
			background-color: #300064;
			padding: 48px 48px;
			display: inline-block;
			text-align: left;
			position: relative;
			border: 0;
			width: 100%;
			align-items: flex-start;
			justify-content: space-between;
			border-top-left-radius: 0.3rem;
    	border-top-right-radius: 0.3rem;

			@media ${device.laptopMedium} {
				padding: 30px;
			}

			.o-header {
				color: #00FFD9;
				font-size: 3.2rem;
				font-weight: 700;
				line-height: 3.8rem;
				max-width: 707px;

				@media ${device.laptopMedium} {
					font-size: 2.2rem;
    			line-height: 2.6rem;
				}
			}

			.o-paragraph {
				color: #fff;
				font-weight: 700;
				padding-top: 8px;
				max-width: 822px;
				font-size: 2.2rem;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					padding: 20px 0 0;
				}
			}

			&:after {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				bottom: -18px;
				width: 100%;
				height: 18px;
				left: 0;
			}
		}

		.modal-inner-content {
			padding: 48px;
			background-color: #fff;
			width: 100%;

			@media ${device.laptopMedium} {
				padding: 30px;
				padding-top: 42px;
			}

			.modal-title {
				color: #000000;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				margin-bottom: 36px;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					margin-bottom: 27px;
					font-size: 1.8rem;
    			line-height: 2.6rem;
				}

				&.list-title {
					margin-bottom: 8px;
				}

				span {
					color: #300064;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					font-weight: 400;
				}
			}

			.modal-points {
				width: 100%;

				@media ${device.laptopMedium} {
					padding-left: 3px;
				}

				ul {
					padding-left: 5px;
					margin-bottom: 36px;
					list-style-type: none;

					@media ${device.laptopMedium} {
						padding-left: 0px;
						margin-bottom: 22px;
					}

					li {
						position: relative;
						color: #000000;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						padding: 0 0 0 25px;
						margin-bottom: 5px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						span {
              font-family: "Gotham", Arial, Helvetica, sans-serif;
							color: #000000;
						}

						&:after {
							content: "";
							position: absolute;
							width: 8px;
							height: 8px;
							background-color: #8000BE;
							top: 13px;
							left: 0;

							@media ${device.laptopMedium} {
								top: 9px;
							}
						}
					}
				}
			}

			.text-center {
				text-align: center;

				.back-eye {
					width: 100%;
					max-width: 568px;
					padding-bottom: 36px;

					@media ${device.laptopMedium} {
						margin-bottom: 22px;
						padding: 0;
					}
				}

				img {
					width: 100%;
					padding-bottom: 36px;

					@media ${device.laptopMedium} {
						padding-bottom: 27px;
					}
				}
			}

			.key-takeway-section {
				border: 4px solid #300064;
				width: 100%;

				.modal-points {
					padding: 40px 40px 0;

					@media ${device.laptopMedium} {
						padding: 15px 12px 0;
					}

					p {
						color: #300064;
						padding-bottom: 10px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						span {
							color: #000000;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						}
					}
				}

				.key-takeway-paragraph {
					background-color: #300064;
    			padding: 36px 56px;
					width: 100%;
					margin-top: 38px;

					@media ${device.laptopMedium} {
						margin-top: 10px;
						padding: 20px;
					}

					h3 {
						color: #00FFD9 !important;
						text-align: left;
						font-size: 2.7rem;
						line-height: 3.8rem;
						margin: auto !important;
						width: 100% !important;
						font-weight: 600;

						@media ${device.laptopMedium} {
							font-size: 2.2rem;
    					line-height: 3.2rem;
						}
					}

					p {
						color: #fff;
						text-align: left;
						padding-top: 16px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						&.o-paragraph {
							margin-bottom: 0 !important;
						}

						~p {
							display: none;
						}
					}
				}
			}

			.modal-ref-text {
				text-align: left;
				font-size: 1.6rem;
				margin-top: 24px;
			}

			.modal-small-paragraph {
				color: #000000;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				font-size: 1.6rem;
				line-height: 1.8rem;
				text-align: left;
				width: 100%;
			}

			.modal-body-small-text {
				text-align: left;
				font-size: 2rem;
				padding: 32px 0;
				color: #000000;
				line-height: 2.6rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				width: 100%;

				@media ${device.laptopMedium} {
					padding: 20px 30px;
					text-align: center;
				}
			}

			.modal-image-container {
				width: 100%;
				display: flex;
				justify-content: center;

				.desktop-image {
					@media ${device.laptopPros} {
						display: none;
					}
				}

				.mobile-image {
					@media ${device.tablet} {
						display: none;
					}
				}

				img {
					width: 100%;
					padding-bottom: 36px;

					@media ${device.laptopMedium} {
						padding-bottom: 27px;
					}
				}

				&.eye-diagram {
					img {
						width: 568px;
						padding-bottom: 36px;

						@media ${device.laptopMedium} {
							padding-bottom: 27px;
							width: 100%;
						}
					}
				}
			}
		}
	}
`;
