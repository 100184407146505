import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, HcpMobileBanner } from '@retina-packages/retina-theme-bootstrap';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { SupportingYourParentsWrapper, HcpSYPmainwrapper } from './styles'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { hcpSYP } from './constants';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { FooterImageGroup } from '@retina-packages/retina-theme-bootstrap/packages/WrapperCSS/community-resources-style';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, readCaseModalDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

/** Renders supporting-your-patients-page template
 *
 * @param props prop
 * @returns
 */
const SupportingYourPatientsTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsSYM = props.pageContext
  const htmlStaticFilesSYM = {
    nonSVGImages: pgContextsSYM.nonSVGImages,
    svgMediaImages: pgContextsSYM.svgMediaImages,
    allMediaDocument: pgContextsSYM.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpSYP.hcpHomeMainMenu, siteFooterMenu: hcpSYP.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = hcpSYP.hcpBanner
  const carouselTitle = hcpSYP.hcpHomeCarousel
  const footerTitle = hcpSYP.hcpFooter
  const exitPopupTitle = hcpSYP.hcpExitPopup
  const hcpSwitcherTitle = hcpSYP.hcpHCPSwitcher
  const topNavTitle = hcpSYP.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const hcpReferenceSYP = footRefHTMLBuilder({ blocks, title: hcpSYP.hcpFootRef });
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpSYP.hcpSiteLogo })
  const anchorelink = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpAnchorelinkPara });
  const htmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpRealWorldPara });
  const seekTrialData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpClinicalTrialPara });
  const bannerHtmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpHTMLBanner });


  const modals: any = readCaseModalDataBuilder(blocks, [hcpSYP.hcpRCSModal1, hcpSYP.hcpRCSModal2, hcpSYP.hcpRCSModal3, hcpSYP.hcpRCSModal4, hcpSYP.hcpRCSModal5])
  const communitySupData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpPatientKnowPara })

  return (
    <HcpSYPmainwrapper className= "hcpsupportingpatientwrapper">
    <HcpMobileBanner className="hcpmobilewrapper hcpsupportpatience">
      {props.pageContext !== null && props.pageContext.metaInfo !== null && (
        <MetaTagContainer metaData={props.pageContext.metaInfo} />
      )}
      <Layout
        title={"siteTitle"}
        location={props.location}
        data={mainMenu}
        modals={modals}
        mdata={footerMenu}
        footerData={footerText}
        audience={audience}
        exitPopData={exitData}
        footref={hcpReferenceSYP}
        hcplinks={hcpSwitcher}
        topNavigation={topNavs}
        siteLogos={siteLogos}
        modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
        gtmBackToTopLabel={hcpSYP.backToTopGTM}
        backToTopContent={retinaConfig.backToTopContent}
        staticMediaFiles={htmlStaticFilesSYM}
        showRefContent={retinaConfig.showRefContent}
        hideRefContent={retinaConfig.hideRefContent}
        hcpValidate={retinaConfig.hcpValidate}
        preIndexUrl = {retinaConfig.preIndexUrl}
        hcpHomeUrl={retinaConfig.hcpHomeUrl}
        hcpPrefix={retinaConfig.hcpPrefix}
        siteHomeUrl={retinaConfig.siteHomeUrl}
        footerClassName={hcpSYP.footerClassName}
        languageConfig={{... retinaConfig.langConfig, ...hcpSYP.redirectLangUrl}}
        {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
      >
        <PageWrapper className='pagewrapper supporting-your-patients'>
          <SupportingYourParentsWrapper className='supportparentswrapper'>
            {/* Banner section */}
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesSYM} html={bannerHtmlData} tagName='section' className='internal-banner o-column--full' />
            </MobileBanner>
            <HTMLRenderer html={anchorelink} key="anchorelink" data={htmlStaticFilesSYM}  />
            <FooterImageGroup className='logo-section o-column--full o-bottom-space '>
              <HTMLRenderer data={htmlStaticFilesSYM} html={communitySupData} tagName='div' className='o-container' simpleImage={true}/>
            </FooterImageGroup>
            <HTMLRenderer html={htmlData} key="htmlData" data={htmlStaticFilesSYM}  />
            <HTMLRenderer html={seekTrialData} key="seekTrialData" data={htmlStaticFilesSYM} />
          </SupportingYourParentsWrapper>
        </PageWrapper>
      </Layout>
    </HcpMobileBanner>
    </HcpSYPmainwrapper>
  )
}

export default SupportingYourPatientsTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpSYPPageQuery
      }
    }
  }
`
